<template  lang="pug">
	v-container(class="section-pim")
		v-row(justify="center")
			//- choose opcion
			v-col( cols="12" lg="12" )
				v-card(class=" p-0")
					v-card-title(class="pb-0")
						h1(align="center" class="title blue-600--text ") Score PDP
					v-card-text(class="pb-0")
						v-container
							
							v-row( class="align-end" )
								v-col(cols="12" offset-md="2" md="3" class="d-flex align-end")
									v-radio-group(v-model="opcion" row)
										v-radio(label="Todos" value="todos" color="primary")
										v-radio(label="Limite" value="limite" color="primary")
								v-col(cols="12"  md="2" class="")
								
									v-text-field(
										label="Cantidad"
										v-model="limit"
										type="number"
										min="1"
										:disabled="opcion=='limite'?false:true")
								v-col(cols="12"  md="3" class="")
								
									v-select(
										label="Categorias"
										:items="categories"
										v-model='filter_categories',
										:loading="loading_categories"
										color="primary"
										class=""
										item-text="Categorías" 
										multiple chips)
								v-col(cols="12"  md="2" class="align-self-center justify-end")
									v-tooltip(top class="")
										template(v-slot:activator="{ on, attrs }")
											v-btn(color="blue-500" class="white--text mr-2" v-bind="attrs" v-on="on" @click="downloadExcel"  :loading="loadind_dowload_excel" :disabled="loadind_dowload_excel")
												v-icon(dark) mdi-download
										<span>Descargar</span>

									v-tooltip(top)
										template(v-slot:activator="{ on, attrs }")
											v-btn(color="blue-500" v-bind="attrs" v-on="on" class="white--text" @click="filter"  :loading="loading" :disabled="loading") 
												v-icon(dark) mdi-filter
										<span>Filtrar</span>
			
									
							v-row
			
								v-data-table(
									:headers="headers"
									:items="score_pdp"
									:search="search"
									:loading="loading"
									:items-per-page="10"
									class="elevation-0")
										
							



</template>
<script>

	import ScorePDP from '../../services/scorepdp.service'

	const scorePDPService = new ScorePDP();

	export default {
		name: 'ScorePDP',
		data() {
			return {
				headers: [
					{ 
						value: 'idu_articulocodigo',
						text: 'código',
						sortable: false
					},
					{ 
						value: 'nomarticuloweb',
						text: 'nomarticuloweb',
						sortable: false
					},
					{ 
						value: 'des_categoria',
						text: 'categoría',
						sortable: false
					},
					{ 
						value: 'visitastotales',
						text: 'visitas totales',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_subcategoria',
						text: 'puntaje subcategoria',
						align: "center",
						sortable: false
					},

					{ 
						value: 'puntaje_marca',
						text: 'puntaje marca',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_descripcion',
						text: 'puntaje descripcion',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_keywords',
						text: 'puntaje keywords',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_imagen',
						text: 'puntaje imagen',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_fotos',
						text: 'puntaje fotos',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_final_binario',
						text: 'puntaje final binario',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_final_nobinario',
						text: 'puntaje final nobinario',
						align: "center",
						sortable: false
					},
					{ 
						value: 'puntaje_final',
						text: 'puntaje final',
						align: "center",
						sortable: false
					},
				],

				loading: false,
				limit: 0,
				search: '',


				score_pdp: [],
				categories: [],
				filter_categories: [],
				loading_categories: false,
				loadind_dowload_excel: false,

				opcion:'todos',
			}
		},

		watch: {
			opcion: function(value) {
				if ( value == 'todos' ) {
					this.limit = 0;
				}
			},
		},

		created(){
			this.initialize();
		},
		methods:{

			initialize() {
				this.filter();
				this.fetchCategories();
			},

			filter () {
				const data = {
					limit: this.limit,
					categories: this.filter_categories
				}
				this.fetchScorePDP(data);
			},

			fetchScorePDP(data) {
				this.loading = true;
				scorePDPService.fetchAll(data)
					.then(response => {
						this.score_pdp = response.data;
						this.loading = false;
					})
					.catch(error => {
						this.loading = false;
						console.log(error);
						console.log(error.error);
					});
			},

			fetchCategories() {
				this.loading_categories = true;
				scorePDPService.categories()
				.then(response => {
					let categories = response.data.categories;
					this.categories = categories;
					this.loading_categories = false;
				
				})
				.catch(errors => {
					console.log(errors);
					this.loading_categories = false;
				});
			}, 

			downloadExcel() {
				this.loadind_dowload_excel = true;
				const data = {
					limit: this.limit,
					categories: this.filter_categories
				}
				scorePDPService.downloadExcel(data)
				.then(response => {
					
					var blob = new Blob([response.data], { type: response.headers['content-type'] });

					var downloadUrl = URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = downloadUrl;
					a.download = 'scores_pdp.xlsx';
					document.body.appendChild(a);
					a.click();
					// remove element
					a.remove()

					this.loadind_dowload_excel = false;
				
				})
				.catch(errors => {
					console.log(errors.error);
					this.loadind_dowload_excel = false;

				});
			}

		},
		
	
	}
</script>
