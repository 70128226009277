import BackendAuthService from "./BackendAuthService";

export default class ScorePDP extends BackendAuthService {

	constructor() {
		super();
	}

    // fetch all data from ScorePDP table
	fetchAll(data) {
		return this.httpCall('interfaz/score-pdp/', {
			method: 'POST',
			data
		});
	}

	// fetch all categories from ScorePDP table
	categories() {
		return this.httpCall('interfaz/score-pdp/categories', {
			method: 'GET'
		});
	}

	downloadExcel(data) {
		return this.httpCall('interfaz/score-pdp/download-excel/', {
			method: 'POST',
			responseType: 'blob',
			data
		});
	}

}
